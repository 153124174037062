<template>
  <div class="property-detail-overview">
    <property-profile class="mb-24" />

    <singgahsini-info
      :loading="infoLoading"
      :occupancy="singgahsiniInfo.occupancy"
      :contract="singgahsiniInfo.contract"
      :handler="singgahsiniInfo.handler"
    />

    <transfer-information
      :loading="disbursementLoading || financialHistoryLoading"
      :disbursement="disbursement"
      :financial-histories="financialHistories"
      :switches="switches"
      @cancel-toggle="revertSwitchValue"
      @fetch-post-auto-disburse-toggle="fetchPostAutoDisburseToggle"
      @fetch-post-pdf-report-toggle="fetchPostPdfReportToggle"
    />
  </div>
</template>

<script>
import propertyApi from '@admin_endpoints/property-detail';
import autoScrollTo from 'Utils/autoScrollTo';
import { endpoints } from '@admin/api/endpoints/property-detail.js';

export default {
  name: 'PropertyDetailOverview',

  components: {
    PropertyProfile: () => import('@admin_organisms/PropertyProfile'),
    SinggahsiniInfo: () => import('./partials/SinggahsiniInfo'),
    TransferInformation: () => import('./partials/TransferInformation'),
  },

  data() {
    return {
      infoLoading: false,
      singgahsiniInfo: {
        occupancy: {},
        contract: {},
        handler: {},
      },
      disbursementLoading: false,
      financialHistoryLoading: false,
      financialHistories: [],
      disbursement: {},
    };
  },

  computed: {
    propertyId() {
      return this.$route.params.propertyId;
    },
    switches() {
      return {
        autoDisburse: this.singgahsiniInfo.contract.is_auto_disbursement,
        pdfReport: this.singgahsiniInfo.contract
          .is_disbursement_notification_enabled,
      }
    },
  },

  async created() {
    await Promise.allSettled([
      this.fetchPropertyAllotment(),
      this.fetchPropertyDisbursement(),
      this.fetchFinancialHistory(),
    ]);

    const { hash } = window.location;
    if (hash) {
      autoScrollTo({ target: hash, gap: 100 });
    }
  },

  methods: {
    async fetchPropertyAllotment() {
      this.infoLoading = true;

      try {
        const { data } = await propertyApi.getPropertyAllotment(
          this.propertyId
        );

        if (data.status) {
          this.singgahsiniInfo.occupancy = data.data.occupancy;
          this.singgahsiniInfo.contract = data.data.singgahsini_contract;
          this.singgahsiniInfo.handler = data.data.handler;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.infoLoading = false;
      }
      return true;
    },

    async fetchPropertyDisbursement() {
      this.disbursementLoading = true;

      try {
        const { data } = await propertyApi.getPropertyDisbursement(
          this.propertyId
        );

        if (data.status) {
          this.disbursement = data.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.disbursementLoading = false;
      }
      return true;
    },

    async fetchFinancialHistory() {
      this.financialHistoryLoading = true;

      try {
        const { data } = await propertyApi.getFinancialHistory(this.propertyId);

        if (data.status) {
          this.financialHistories = data.data || [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.financialHistoryLoading = false;
      }
      return true;
    },

    revertSwitchValue(payload) {
      this.switches[payload] = !this.switches[payload];
    },

    async fetchPostAutoDisburseToggle(payload, callback) {
      const response = payload
      ? await endpoints.postAutoDisburseActivate(
        this.singgahsiniInfo.contract.id
      )
      : await endpoints.postAutoDisburseDeactivate(
        this.singgahsiniInfo.contract.id
      );

      if (callback) {
        response ? callback(true) : callback(false);
      }
    },

    async fetchPostPdfReportToggle(payload, callback) {
      const response = payload
      ? await endpoints.postPdfReportActivate(
        this.singgahsiniInfo.contract.id
      )
      : await endpoints.postPdfReportDeactivate(
        this.singgahsiniInfo.contract.id
      );

      if (callback) {
        response ? callback(true) : callback(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyDetailOverview.scss"></style>
