export default ({ target, gap = 0 }) => {
  const targetEl = document.querySelector(target);
  const clientRectObj =
    typeof targetEl?.getBoundingClientRect === 'function'
      ? targetEl.getBoundingClientRect()
      : {};

  if (typeof clientRectObj.top !== 'undefined') {
    window.scroll({
      left: 0,
      top: clientRectObj.top - gap,
      behavior: 'smooth',
    });
  }
};
